* {
	margin: 0;
	padding: 0;
	font-family: "Poppins", sans-serif;
	/* border: 1px solid gray; */
}
img {
	max-width: 100%;
}
body.active {
	max-height: 100vh;
	overflow: hidden;
}
.fl-page {
	overflow: hidden;
}
.container {
	max-width: 1240px !important;
}
.first-section {
	position: relative;
}
.first-padding {
	padding-top: 170px;
}
.first-section::before {
	content: "";
	position: absolute;
	background: url(./assets/header-img.svg);
	width: 82.5vw;
	height: 55.06vw;
	background-size: 93% auto;
	background-position: top right;
	background-repeat: no-repeat;
	right: 0;
	top: 0;
	pointer-events: none;
}
.first-section-full-background h1 {
	color: #fff;
}
.first-section .container {
	position: relative;
	z-index: 9;
}
a {
	text-decoration: none;
	font-size: 14px;
	transition: all 0.2s ease;
}
h1 {
	font-size: 62px;
	color: #465578;
	line-height: 1.2;
	font-weight: 700;
	text-transform: normal;
}
h2 {
	font-size: 36px;
	color: #465578;
	line-height: 1.2;
	font-weight: 600;
}
h3 {
	font-size: 26px;
	font-weight: 600;
	color: #465578;
}
h4 {
	font-size: 20px;
	font-weight: 500;
	color: #465578;
}
p {
	font-size: 14px;
	color: #465578;
	line-height: 1.8;
}
input:focus {
	box-shadow: none !important;
	border-color: #f5faff !important;
}
button {
	border: none;
	background: transparent;
}
form:not(.upload-form) button,
.btn-linear {
	height: 50px;
	padding: 0 36px;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	border-radius: 5px;
	background: linear-gradient(78deg, #7fc2ff 14.97%, #6c63ff 81.1%);
	color: #fff;
	font-weight: 600;
	font-size: 14px;
	background-size: 140% auto;
	background-position: right center;
	transition: all 0.3s ease;
	border: none;
}
@media (max-width: 360px) {
	form:not(.upload-form) button,
	.btn-linear {
		font-size: 12px;
	}
}
form:not(.upload-form) button:hover,
.btn-linear:hover {
	background-position: left center;
}
form:not(.upload-form) button {
	outline: none;
	border: none;
	min-width: 164px;
}
.btn-white {
	height: 50px;
	padding: 0 36px;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	border-radius: 5px;
	background: #fff;
	color: #fff;
	font-weight: 600;
	font-size: 14px;
	transition: all 0.3s ease;
	border: 1px solid #fff;
	color: #465578;
}
.btn-white:hover {
	background: transparent;
	color: #fff;
}
.form-all label {
	padding-left: 17px;
	font-size: 14px;
	margin-bottom: 8px;
}
.form-all .form-group {
	margin-bottom: 15px;
}
.form-all .form-group input {
	border: 1px solid #f5faff;
	background: #f5faff;
	padding-left: 17px;
	height: 50px;
	font-size: 14px;
	color: #465578;
}
.form-all .form-group textarea {
	font-size: 14px;
	color: #465578;
	border: 1px solid #f5faff;
	background: #f5faff;
	padding-left: 17px;
}
.form-all .form-group input::placeholder {
	color: #bbd3eb;
}
.white-wrapper-all {
	border: 1px solid rgba(127, 194, 255, 0.19);
	border-radius: 40px;
	padding: 67px;
	background: #fff;
	box-shadow: 0 40px 80px rgba(54, 81, 144, 0.12);
	position: relative;
	z-index: 9;
}
.calendly-inline-widget {
	height: auto !important;
}
.calendly-inline-widget iframe {
	height: 700px !important;
}
@media (max-width: 865px) {
	.calendly-inline-widget iframe {
		height: 600px !important;
	}
}
.first-section-full-background {
	background-image: url(./assets/salusion-guide-background.svg);
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	padding-bottom: 104px;
	background-position: bottom center;
}
.video-wrapper-all {
	position: relative;
	cursor: pointer;
}
.video-wrapper-all video {
	border-radius: 40px;
}
.video-wrapper-all button {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
}
.hra-padding-top {
	padding-top: 100px;
}
@media (max-width: 1440px) {
	.first-section::before {
		background-size: 100% auto;
	}
}
@media (max-width: 1200px) {
	.container {
		padding-left: 30px;
		padding-right: 30px;
	}
	h1 {
		font-size: 46px;
	}
}
@media (max-width: 1024px) {
	h1 {
		font-size: 42px;
	}
	h2 {
		font-size: 32px;
	}
	.first-section-full-background {
		background-size: cover;
	}
}
@media (max-width: 767px) {
	h1 {
		font-size: 32px;
	}
	h2 {
		font-size: 30px;
	}
	h3 {
		font-size: 24px;
	}
	.container {
		padding-left: 20px;
		padding-right: 20px;
	}
	.first-section::before {
		background-size: 70% auto;
	}
	.first-section {
		padding-top: 120px;
	}
	p {
		line-height: 1.6;
	}
	.white-wrapper-all {
		padding: 40px 20px 25px;
		border-radius: 20px;
	}
	.calendly-inline-widget iframe {
		padding: 0 10px !important;
	}
	.first-padding {
		padding-top: 120px;
	}
	.video-wrapper-all video {
		border-radius: 20px;
	}
	.video-wrapper-all button {
		width: 70px;
	}
	.first-section-full-background {
		padding-bottom: 60px;
	}
	.salusion-product-video__wrapper iframe {
		height: 250px;
	}
	.hra-padding-top {
		padding-top: 50px;
	}
}
