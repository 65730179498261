#create_account {
  padding-bottom: 180px;
}
.create-acc {
  max-width: 600px;
  display: block;
  margin: 0 auto;
}
.create-account-wrapper {
  text-align: center;
}
.create-account-wrapper p {
  margin: 15px 0 40px;
  color: rgb(127, 194, 255);
  font-size: 22px;
}
@media (max-width: 1024px) {
  .create-account-wrapper p {
    font-size: 18px;
  }
  #create_account {
    padding-bottom: 100px;
  }
}
@media (max-width: 767px) {
  .create-account-wrapper p {
    font-size: 16px;
  }
  .create-account-wrapper p {
    margin: 15px 0 30px;
  }
}
