#footer {
  background: #fafcff;
}
.footer-copyright {
  background: linear-gradient(78deg, #7fc2ff 14.97%, #6c63ff 81.1%);
}
.footer-copyright p {
  margin-bottom: 0;
  padding: 18px 0 14px;
  text-align: center;
  color: #fff;
}
.footer-links-wrapper {
  padding-bottom: 60px;
  padding-top: 127px;
}
.footer-links {
  display: flex;
  flex-direction: column;
}
.footer-links h5 {
  font-size: 14px;
  color: #465578;
  font-weight: 600;
  margin-bottom: 15px;
}
.footer-links a {
  color: #6579a8;
  margin-bottom: 15px;
}
.footer-links a:hover {
  color: #6c63ff;
}
.footer-logo img {
  width: 175px;
}
@media (max-width: 1024px) {
  .footer-links {
    margin-bottom: 30px;
  }
  .footer-links-wrapper {
    padding-top: 80px;
    padding-bottom: 40px;
  }
}
@media (max-width: 767px) {
  .footer-links {
    padding-left: 0;
  }
  .footer-links {
    margin-bottom: 15px;
  }
  .footer-logo {
    margin-bottom: 30px;
  }
  .footer-links-wrapper {
    padding-top: 60px;
    padding-bottom: 25px;
  }
}
