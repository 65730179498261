.header-wrapper {
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
	width: 100%;
	z-index: 10;
}
.main-nav {
	display: flex;
	align-items: center;
	justify-content: space-between;
	position: relative;
	z-index: 1;
	padding-top: 24px;
}
.main-nav__items {
	display: flex;
	gap: 40px;
	align-items: center;
}
.main-nav__items > * {
	line-height: 1.4;
}
.main-nav__items > a {
	color: #fff;
	position: relative;
	padding: 10px 0;
}
.main-nav__items > a:not(:last-of-type)::before {
	content: "";
	position: absolute;
	background: #fff;
	width: 0%;
	height: 1px;
	bottom: -3px;
	transition: all 0.2s ease;
}
.main-nav__items > a:hover:not(:last-of-type)::before {
	width: 70%;
}
.main-nav__items > a:last-of-type {
	width: 120px;
	height: 50px;
	display: flex;
	justify-content: center;
	align-items: center;
	background: #ffffff;
	border: 1px solid #fff;
	border-radius: 5px;
	color: #465578;
	font-weight: 600;
}
.main-nav__items a:last-of-type:hover {
	background: transparent;
	color: #fff;
}
.main-nav__hamburger {
	display: none;
}
.has-dropdown {
	position: relative;
}
@media (min-width: 1024px) {
	.has-dropdown:hover .dropdown-menu-all {
		transform: translateY(0px);
		opacity: 1;
		pointer-events: all;
	}
	.dropdown-menu-all {
		opacity: 0;
		pointer-events: none;
		transform: translateY(20px);
		position: absolute;
		top: 100%;
		right: 0;
	}
}

.dropdown-menu-all {
	width: max-content;
	background: #fff;
	padding: 20px 20px 15px;
	border-radius: 15px;
	box-shadow: 0 10px 20px rgba(54, 81, 144, 0.2);
	transition: all 0.3s ease;
}
.dropdown-menu-all a {
	color: #465578;
	display: flex;
	align-items: center;
	margin-bottom: 12px;
}
.dropdown-menu-all a {
	color: #465578 !important;
}
.dropdown-menu-icon {
	flex-basis: 35px;
	max-width: 35px;
}
.dropdown-menu-icon img {
	width: 100%;
}
.has-dropdown button {
	background: none;
	border: none;
	margin-left: 5px;
}
.has-dropdown a {
	color: #fff;
}
.dropdown-menu-text {
	flex: 1;
	padding-left: 10px;
}
@media (max-width: 1024px) {
	.main-nav {
		padding-top: 17px;
	}
	.main-nav__items {
		position: fixed;
		background: #f5f9ff;
		width: 100%;
		height: 100vh;
		top: 0;
		left: 0;
		flex-direction: column;
		justify-content: center;
		gap: 10px;
		z-index: 98;
		opacity: 0;
		pointer-events: none;
		transition: all 0.2s ease;
	}
	.main-nav__items.active {
		opacity: 1;
		pointer-events: all;
	}
	.main-nav__items a {
		color: #465578;
		font-size: 16px;
	}
	.main-nav__hamburger {
		z-index: 99;
		display: flex;
		flex-direction: column;
		gap: 5px;
	}
	.main-nav__hamburger span {
		background: #fff;
		width: 35px;
		height: 2px;
		transition: all 0.2s ease;
	}
	.main-nav__hamburger.active span {
		background: #465578;
	}
	.main-nav__hamburger.active .line-1 {
		transform: rotate(45deg);
	}
	.main-nav__hamburger.active .line-2 {
		transform: rotate(-45deg);
		margin-top: -7px;
	}
	.has-dropdown button img {
		filter: invert(1);
	}
	.dropdown-menu-all {
		max-height: 0;
		overflow: hidden;
		padding: 0;
		transition: all 0.6s ease;
		border-radius: 8px;
		margin-top: 10px;
	}
	.dropdown-menu-all.show {
		max-height: 1000px;
	}
	.dropdown-menu-all a {
		font-size: 14px;
		padding: 10px;
		margin-bottom: 0;
	}
	.dropdown-menu-icon {
		flex-basis: 25px;
		max-width: 25px;
	}
	.has-dropdown {
		text-align: center;
	}
	.main-nav__items a:hover {
		color: #465578;
	}
}
@media (max-width: 767px) {
	.main-nav__logo img {
		width: 110px;
	}
	.header-wrapper::before {
		background-size: 80%;
		background-repeat: no-repeat;
		background-position: top right;
	}
}
