.pricing-wrapper h1 {
  text-align: center;
  margin-bottom: 40px;
}
#pricing {
  padding-bottom: 120px;
}
.pricing-heading {
  text-align: center;
  background: rgba(107, 99, 254, 0.8);
  border-radius: 20px 20px 0 0;
  padding: 25px 20px;
}
.pricing-heading h2 {
  font-size: 24px;
  color: #fff;
  margin-bottom: 0;
}
.pricing-wrapper-row .col-12:last-of-type .pricing-heading {
  background: rgb(127, 194, 255);
}
.pricing-single {
  border: 1px solid #acbee8;
  border-radius: 23px;
}
.price-content {
  text-align: left;
  padding: 25px;
}
.pricing-wrapper-row {
  max-width: 900px;
  margin: 0 auto;
}
.price-wrapper-parent span {
  font-size: 64px;
  color: #465578;
  line-height: 1;
  font-weight: 600;
}
.price-wrapper-parent {
  margin-bottom: 30px;
}
.price-wrapper-parent p {
  margin-top: 6px;
}
.price-content h3 {
  margin-top: 20px;
}
.price-content p {
  max-width: 350px;
}
@media (max-width: 1024px) {
  .pricing-single {
    margin-bottom: 25px;
  }
}
@media (max-width: 767px) {
  #pricing {
    padding-bottom: 60px;
  }

  .pricing-wrapper h1 {
    margin-bottom: 30px;
    min-height: 80px;
  }
  .pricing-heading h2 {
    font-size: 20px;
  }
  .pricing-heading {
    padding: 20px;
  }
  .price-wrapper-parent span {
    font-size: 46px;
  }
  .price-content {
    padding: 25px 20px;
  }
}
