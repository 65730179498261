#start_hra {
	position: relative;
	z-index: 2;
}
#start_hra::before {
	content: "";
	position: absolute;
	/* background: linear-gradient(to top, #fafcff 50%, rgba(255, 255, 255, 0) 50%); */
	width: 100%;
	height: 100%;
}
.hra-contact-wrapper h2 {
	font-size: 70px;
	color: #7fc2ff;
	text-align: center;
	margin-bottom: -30px;
}
.hra-contact {
	padding-right: 40px;
}
.btn-linear {
	height: 50px;
	padding: 0 36px;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	border-radius: 5px;
	background: linear-gradient(78deg, #7fc2ff 14.97%, #6c63ff 81.1%);
	color: #fff;
	font-weight: 600;
	font-size: 14px;
	background-size: 140% auto;
	background-position: right center;
	transition: all 0.3s ease;
	border: none;
}
@media (max-width: 360px) {
	.btn-linear {
		font-size: 12px;
		padding: 0 15px;
	}
}
@media (max-width: 1024px) {
	.hra-contact {
		padding-right: 0;
	}
	.hra-contact-wrapper h2 {
		font-size: 60px;
		margin-bottom: -35px;
	}
}
@media (max-width: 865px) {
	.hra-contact-wrapper h2 {
		font-size: 50px;
		margin-bottom: 0px;
	}
	.hra-contact {
		margin-bottom: 30px;
	}
}
