.privacy-inner-child-space {
  padding-left: 20px;
}
.privacy-inner-child li {
  color: #465578;
  font-size: 14px;
  margin-bottom: 5px;
}
.privacy-wrapper h1,
.privacy-wrapper h3 {
  text-align: center;
}
.privacy-wrapper h3 {
  margin: 20px 0 35px;
  color: rgb(127, 194, 255);
}
.terms-wrapper h1 {
  margin-bottom: 30px;
}
.privacy-section {
  padding-bottom: 120px;
}
@media (max-width: 767px) {
  .privacy-section {
    padding-bottom: 80px;
  }
}
