.salusion-guide-wrapper {
	text-align: center;
	margin: 0 auto;
}

.salusion-guide-wrapper h1 {
	color: #fff;
	font-size: 70px;
}

.salusion-guide-wrapper p {
	color: #fff;
	margin: 25px 0;
	font-size: 24px;
}

.salusion-guide-btn-wrapper {
	display: flex;
	align-items: center;
	gap: 20px;
	justify-content: center;
}

.salusion-guide-btn-wrapper a {
	padding: 14px 36px;
	font-weight: 600;
	color: #465678;
	border-radius: 5px;
}

.salusion-guide-btn-wrapper a:first-of-type {
	background: #ffffff;
	border: 1px solid #fff;
}

.salusion-guide-btn-wrapper a:last-of-type {
	background: #80c2ff;
	border: 1px solid #80c2ff;
}

.salusion-guide-btn-wrapper a:hover {
	background: transparent;
	color: #fff;
}

.salusion-product-video {
	margin: 50px auto 0px;
	padding-bottom: 56.25%;
	width: 100%;
	max-width: 1280px;
	position: relative;
	overflow: hidden;
	border-radius: 40px;
}

.salusion-product-video img {
	cursor: pointer;
}

.salusion-product-video img,
.salusion-product-video iframe {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	border-radius: 40px;
}

.salusion-product-video:hover {
	box-shadow: #0000001a 0 0 125px 25px;
}

#price_comparison {
	padding-bottom: 100px;
}

#price_comparison .container {
	max-width: 1350px !important;
}

.price-comparison-wrapper {
	background: linear-gradient(78deg, #7fc2ff, #6c63ff);
	padding: 66px 140px;
	border-radius: 20px;
}

#price_comparison h2 {
	font-size: 70px;
	text-align: center;
	margin-bottom: 40px;
	color: #fff;
}

.products-hra-wrapper #start_hra {
	padding-bottom: 100px;
}

.comparison-table {
	background: rgba(255, 255, 255, 0.2);
	border-radius: 20px;
	padding: 50px 67px;
	margin: 60px -66px 0;
}

.pricing-table tr:first-of-type td {
	border-top: 1px solid #acbee8;
}

.table-all td,
.table-all tr {
	min-width: 180px;
	max-width: 180px;
	background: transparent;
	font-size: 14px;
}

.table-all td:first-of-type,
.table-all tr:first-of-type {
	min-width: 350px;
	max-width: 350px;
	width: 350px;
}

.table-all th:first-of-type {
	text-align: left;
}

.table-all th {
	background: transparent;
	font-weight: 600;
	color: #fff;
	font-size: 20px;
	text-align: center;
	padding: 12px 0;
	border: none !important;
}

.table-all td:first-of-type {
	text-align: left;
}

.table-all td {
	text-align: center;
	padding: 15px 0px;
	border: none !important;
	color: #fff !important;
	font-size: 24px;
	font-weight: 600;
}

.table-all td:not(:first-of-type) span {
	padding: 18px 20px;
	min-width: 181px;
	display: inline-block;
	background: rgba(255, 255, 255, 0.2);
	border-radius: 10px;
}

.comparison-table tr th:last-of-type,
.table-all td:last-of-type {
	border-right: 0 !important;
}

.table-all {
	vertical-align: middle;
}

.pricing-table h3 {
	margin-bottom: 25px;
}

#resource_center {
	padding: 100px 0;
}

#resource_center h2 {
	text-align: center;
	margin-bottom: 50px;
	font-size: 70px;
}

.resource-center__wrapper {
	background: #f8f8ff;
	padding: 78px 104px;
	border-radius: 40px;
}

.video-single {
	margin-bottom: 24px;
}

.video-single__wrapper {
	position: relative;
	border-radius: 20px;
	overflow: hidden;
	transition: all 0.2s ease;
	cursor: pointer;
}

.resource-center__wrapper a:hover .video-single__wrapper {
	box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

.video-single__wrapper img {
	width: 100%;
}

.video-single__wrapper video {
	aspect-ratio: 13 / 9;
	object-fit: cover;
	display: flex;
}

.video-single__wrapper button {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background: none;
	border: none;
}

.video-single a {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 50px;
	width: 174px;
	margin: 25px auto 0;
	background: linear-gradient(78deg, #7fc2ff 14.97%, #6c63ff 81.1%);
	padding: 1px;
	border-radius: 10px;
}

.video-single a:hover span {
	background: transparent;
}

.video-single a span {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	background: #fff;
	border-radius: 10px;
	font-weight: 600;
	color: #465578;
	font-size: 14px;
	transition: all 0.2s ease;
}

.video-single h3 {
	text-align: center;
	margin-top: 30px;
}

@media (max-width: 1200px) {
	.table-all td:not(:first-of-type) span {
		min-width: 160px;
	}

	.price-comparison-wrapper {
		padding: 66px 90px;
	}

	.table-all td span {
		min-width: 150px;
	}

	#resource_center h2,
	#price_comparison h2 {
		font-size: 52px;
	}
}

@media (max-width: 1024px) {
	.table-all td:not(:first-of-type) span {
		min-width: 120px;
	}

	#price_comparison h2 {
		font-size: 42px;
	}

	#price_comparison {
		padding: 20px 0 80px;
	}

	.table-all th {
		font-size: 20px;
	}

	.comparison-table {
		margin: 60px -40px 0px;
		padding: 45px 40px;
	}

	#resource_center {
		padding: 40px 0 80px;
	}

	#price_comparison h2 {
		margin-bottom: 20px;
	}

	.table-all td {
		font-size: 18px;
	}

	.table-all td:first-of-type,
	.table-all tr:first-of-type {
		min-width: 250px;
		max-width: 250px;
		width: 250px;
	}

	.resource-center__wrapper {
		padding: 60px 35px;
	}

	.video-single h3 {
		font-size: 20px;
	}

	.salusion-guide-wrapper h1 {
		font-size: 60px;
	}
}

@media (max-width: 767px) {
	#price_comparison {
		padding: 60px 0;
	}

	#price_comparison h2 {
		font-size: 30px;
		margin-bottom: 15px;
	}

	.table-all th {
		font-size: 16px;
	}

	.table-all td,
	.table-all tr {
		font-size: 12px;
		padding: 12px 0;
	}

	.table-all td,
	.table-all tr {
		min-width: 220px;
		max-width: 220px;
	}

	.comparison-table {
		padding: 25px;
		margin: 20px -24px 0;
		border-radius: 10px;
	}

	.salusion-guide-btn-wrapper {
		flex-direction: column;
		gap: 10px;
	}

	.salusion-guide-wrapper p {
		margin: 20px 0;
	}

	#products_hero {
		padding-bottom: 60px;
	}

	#resource_center {
		padding: 40px 0 40px;
	}

	#resource_center h2 {
		margin-bottom: 40px;
	}

	.video-single {
		margin-bottom: 30px;
	}

	.video-single a {
		margin: 30px auto 0;
	}

	.salusion-guide-wrapper h1 {
		font-size: 32px;
	}

	.salusion-guide-wrapper p {
		font-size: 16px;
	}

	#resource_center h2,
	#price_comparison h2 {
		font-size: 30px;
	}

	.resource-center__wrapper {
		padding: 45px 20px 20px;
		border-radius: 20px;
	}

	.video-single a {
		margin: 20px auto 0;
	}

	#price_comparison {
		padding: 15px 0 40px;
	}

	.products-hra-wrapper #start_hra {
		padding-bottom: 20px;
	}

	.price-comparison-wrapper {
		padding: 45px 20px;
	}

	.table-all td:first-of-type,
	.table-all tr:first-of-type {
		min-width: 195px;
		max-width: 195px;
		width: 195px;
	}

	.table-all td span {
		min-width: 100px;
		padding: 10px 20px;
		border-radius: 5px;
	}

	.table-all td,
	.table-all tr {
		min-width: 150px;
		max-width: 150px;
		padding-top: 5px;
		padding-bottom: 5px;
	}

	.table-all td img {
		width: 25px;
	}

	.comparison-table td {
		padding-top: 10px;
		padding-bottom: 10px;
		min-width: 150px;
		max-width: 150px;
	}
}
