#hero {
	padding-bottom: 181px;
}
.hero-content h1 {
	max-width: 579px;
	font-size: 75px;
}
.hero-content p {
	font-size: 18px;
	max-width: 514px;
	margin: 15px 0 25px;
}
/* hra company */
#hra_company {
	position: relative;
	z-index: 9;
	padding-bottom: 58px;
}
#hra_company .container #referral .container {
	max-width: 1324px !important;
}
.hra-wrapper {
	background: linear-gradient(to bottom, #f8fbff, #f8f8ff);
	border-radius: 40px;
	padding: 59px 41px;
}
.hra-wrapper h2 {
	text-align: center;
	margin-bottom: 40px;
}
.package-single {
	background: #ffffff;
	border-radius: 20px;
	text-align: center;
	padding: 34px 30px 31px;
}
.package-heading {
	display: flex;
	gap: 20px;
	align-items: center;
	justify-content: center;
}
.package-heading h3 {
	margin-bottom: 0;
}
.package-single h4 {
	max-width: 160px;
	margin: 0 auto 22px;
}
.package-single .separator {
	background: #acbee8;
	width: 100%;
	height: 1px;
	margin: 17px 0 22px;
}
.price-wrapper {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-bottom: 25px;
}
.price-wrapper span {
	font-size: 26px;
	color: #465578;
}
.price-wrapper p {
	margin-bottom: 0;
	font-size: 16px;
}
.package-single a {
	color: #6c63ff;
	font-weight: 600;
	display: flex;
	align-items: center;
	gap: 10px;
	justify-content: center;
}
.package-single a img {
	transition: all 0.2s ease;
}
.package-single a:hover img {
	transform: translateX(2px);
}
/* salusion simplifies */
#salusion_simplifies {
	padding-right: calc((100vw - 1234px) / 2);
	padding-bottom: 102px;
}
@media (max-width: 1300px) {
	#salusion_simplifies {
		padding-right: 30px;
	}
}
.simplifies-img {
	padding-right: 30px;
}
.simplifies-img img {
	width: 100%;
}
.simplifies-accordion .accordion-header button {
	background-color: transparent;
	box-shadow: none;
	padding: 0;
	padding-bottom: 20px;
	padding-top: 20px;
	font-size: 20px;
	font-weight: 600;
	color: #465578;
}
.simplifies-accordion .accordion-item:first-of-type {
	border: none !important;
}
.simplifies-accordion .accordion-item {
	border: none;
	border-top: 1px solid #acbee8;
	border-radius: 0;
}
.simplifies-accordion .accordion-body {
	padding: 0;
	padding-bottom: 20px;
}
.simplifies-accordion .accordion-body ul {
	padding-left: 28px;
	margin-bottom: 0 !important;
}
.simplifies-accordion .accordion-body li {
	color: #465578;
	margin-bottom: 5px;
	font-size: 14px;
}
/* benefits */
#benefits {
	padding-bottom: 100px;
}
.benefits-wrapper {
	background: linear-gradient(78deg, #7fc2ff 14.97%, #6c63ff 81.1%);
	padding: 70px 100px;
	border-radius: 40px;
}
.benefits-content p {
	font-size: 18px;
	color: #fff;
	margin-bottom: 0px;
}
.benefits-content h2 {
	font-size: 55px;
	color: #fff;
	max-width: 361px;
}
.benefits-number-wrapper {
	background: rgba(255, 255, 255, 0.2);
	border-radius: 20px;
	text-align: center;
	padding: 60px 0;
}
.benefits-number-wrapper h2 {
	font-size: 68px;
	font-weight: 700;
	color: #fff;
	margin-bottom: 0;
}
.benefits-number-wrapper h4 {
	font-weight: 600;
	color: #fff;
}
#testimonials {
	padding-bottom: 80px;
}
.slider-wrapper {
	padding-left: calc((100vw - 1228px) / 2);
}
.slider-button-wrapper {
	margin: 30px 0 40px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}
.slider-switch-buttons {
	display: flex;
	align-items: center;
	gap: 30px;
}
.slider-switch-buttons button {
	width: 150px;
	height: 50px;
	display: flex;
	justify-content: center;
	align-items: center;
	transition: all 0.2s ease;
	font-weight: 600;
	border: 1px solid #acbee8;
	border-radius: 5px;
	font-size: 14px;
}
.slider-switch-buttons button.active {
	background: linear-gradient(78deg, #7fc2ff 14.97%, #6c63ff 81.1%);
	color: #fff;
	border-color: #fff;
}
.testimonial-card-single {
	border: 1px solid #acbee8;
	border-radius: 10px;
	padding: 30px 27px;
}
.testimonial-header {
	display: flex;
	align-items: center;
}
.testimonial-header p,
.testimonial-header h4 {
	margin-bottom: 0;
	font-size: 16px;
	font-weight: 600;
}
.testimonial-review {
	margin-top: 25px;
}
.testimonials-hashtags span {
	margin-top: 1rem;
	display: block;
	font-size: 12px;
	color: #6f72ff;
	font-weight: 600;
}
.slider-arrow-custom {
	display: flex;
	align-items: center;
	gap: 15px;
}
.slider-arrow-custom button {
	color: #acbee8;
	border: 1px solid #acbee8;
	border-radius: 10px;
	width: 60px;
	height: 60px;
	display: flex;
	justify-content: center;
	align-items: center;
	transition: all 0.2s ease;
}
.slider-arrow-custom button:hover {
	background: linear-gradient(78deg, #7fc2ff 14.97%, #6c63ff 81.1%);
	border: 1px solid #fff;
}
.slider-arrow-custom button:hover svg path {
	fill: #fff;
}
@media (max-width: 1200px) {
	.benefits-wrapper {
		padding: 70px 60px;
	}
	.slider-wrapper {
		padding-left: 30px;
	}
}
@media (max-width: 1024px) {
	.hero-content h1 {
		font-size: 42px;
	}
	#hero {
		padding-bottom: 100px;
	}
	.package-single {
		margin-bottom: 20px;
	}
	#salusion_simplifies {
		padding-bottom: 70px;
	}
	.benefits-content h2 {
		font-size: 42px;
	}
	.benefits-content p {
		font-size: 16px;
	}
	.benefits-number-wrapper h2 {
		font-size: 46px;
	}
	.benefits-number-wrapper {
		padding: 40px 0;
		margin-top: 20px;
	}
}
@media (max-width: 767px) {
	.hero-content h1 {
		font-size: 36px;
	}
	#hero {
		padding-bottom: 40px;
	}
	.hero-content p {
		font-size: 16px;
	}
	.hero-animation {
		padding-top: 20px;
		max-width: 250px;
		margin: 0 auto;
	}
	.hra-wrapper {
		padding: 35px 20px 20px;
		border-radius: 25px;
	}
	.hra-wrapper h2 {
		margin-bottom: 30px;
	}
	.package-single {
		padding: 34px 20px 30px;
	}
	.package-heading {
		gap: 10px;
	}
	#salusion_simplifies {
		padding-bottom: 45px;
	}
	.simplifies-accordion {
		padding-left: 20px;
		margin-top: 35px;
	}
	.simplifies-accordion .accordion-header button {
		font-size: 16px;
		padding-top: 15px;
		padding-bottom: 15px;
	}
	.benefits-wrapper {
		padding: 35px 20px;
		border-radius: 20px;
	}
	.benefits-content h2 {
		font-size: 36px;
		margin: 20px 0;
	}
	#benefits {
		padding-bottom: 60px;
	}
	.slider-wrapper {
		padding-left: 20px;
		padding-right: 20px;
	}
	.slider-button-wrapper {
		margin: 30px 0;
		flex-direction: column;
	}
	.slider-switch-buttons {
		gap: 10px;
	}
	.slider-switch-buttons,
	.slider-arrow-custom {
		width: 100%;
	}
	.slider-switch-buttons button {
		width: 130px;
		height: 45px;
		font-size: 12px;
	}
	.slider-arrow-custom {
		justify-content: flex-end;
		margin-top: 20px;
		gap: 8px;
	}
	.slider-arrow-custom button {
		height: 50px;
		width: 50px;
		border-radius: 8px;
	}
	#testimonials {
		padding-bottom: 60px;
	}
}
