#contact {
	padding-bottom: 100px;
}

.contact-wrapper h1 {
	max-width: 700px;
	margin: 0 auto 50px;
	text-align: center;
}

.contact-form label {
	display: none;
}

.contact-form input::placeholder {
	color: #6579a8 !important;
}

.contact-form textarea {
	height: 250px;
}

.contact-form-wrapper h2 {
	color: #7fc2ff;
	margin-bottom: 30px;
}

@media (max-width: 767px) {
	#contact {
		padding-bottom: 60px;
	}

	.contact-form-wrapper h2 {
		margin-bottom: 20px;
	}

	.contact-wrapper h1 {
		margin-bottom: 30px;
	}

	.contact-form-right {
		margin-top: 20px;
	}
}
