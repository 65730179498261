.rules-all h1 {
	text-align: center;
	margin-bottom: 50px;
}
#gsehra_rules {
	background-position: top center !important;
}
.gsehra-rules-reg-wrapper h3 {
	font-size: 18px;
	line-height: 1.8;
	margin-bottom: 20px;
	max-width: 1126px;
}
.guide-accordion-all {
	margin-top: 40px;
}
.white-wrapper-all .accordion-item {
	border: 1px solid #acbee8;
	margin-bottom: 20px;
	border-radius: 10px;
}
.white-wrapper-all .accordion-item button {
	background: transparent;
	box-shadow: none !important;
	font-weight: 600;
	font-size: 18px;
	padding: 20px 24px;
}
.white-wrapper-all .accordion-body {
	padding: 10px 24px 20px;
}
.white-wrapper-all .accordion-body p,
.white-wrapper-all .accordion-body li {
	font-size: 16px;
	color: #465578;
}
.rules-all {
	background-size: 100% auto;
	background-position: top center;
}
@media (max-width: 767px) {
	.rules-all h1 {
		margin-bottom: 40px;
	}
	.gsehra-rules-reg-wrapper h3 {
		font-size: 16px;
		line-height: 1.5;
	}
	.rules-all {
		background-size: 235% auto;
	}
	.white-wrapper-all .accordion-item button {
		font-size: 16px;
		padding: 15px 18px;
	}
	.white-wrapper-all .accordion-body p,
	.white-wrapper-all .accordion-body li {
		font-size: 14px;
	}
	.white-wrapper-all .accordion-body {
		padding: 5px 18px 20px;
	}
	.white-wrapper-all .accordion-item {
		margin-bottom: 10px;
	}
}
