#employer_calculator_hero {
  background: linear-gradient(to right, #7cb6ff, #6c63ff, #7db7ff);
  padding-bottom: 60px;
}
#employer_calculator_hero h1 {
  text-align: center;
  color: #fff;
}
#upload_section {
  padding: 80px 0;
}
.upload-form button {
  border: 1px solid #80c2ff;
  height: 150px;
  width: 160px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  border-radius: 20px;
  margin: 0 auto;
  transition: all 0.2s ease;
  background: #fff;
}
.upload-form button:hover {
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}
.download-btn-wrapper {
  text-align: center;
  margin-top: 20px;
}
.dowload-btn {
  margin-top: 20px;
  color: #465578;
  font-size: 16px;
}
.table-wrapper {
  margin-top: 20px;
}
#calculator_accordion {
  padding-bottom: 100px;
}
@media (max-width: 767px) {
  #employer_calculator_hero {
    padding-bottom: 40px;
  }
  #upload_section {
    padding: 60px 0;
  }
  #calculator_accordion {
    padding-bottom: 60px;
  }
  .table-wrapper td {
    min-width: 120px;
    font-size: 14px;
  }
  .table-wrapper {
    margin-top: 30px;
  }
  .upload-form button {
    height: 120px;
    width: 130px;
    font-size: 14px;
    border-radius: 15px;
  }
  .dowload-btn {
    font-size: 14px;
  }
}
